import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/png/icon-layout.png";
import iconLayoutAlt from "../../assets/image/png/icon-layot-25.png";
import iconResponsive from "../../assets/image/png/icon-responsive.png";

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Powerful Data" iconImage={iconLayout}>
              Using LoanerHub's real-time vehicle management platform - You and
              your staff will always be looking at the same information, event
              when you are in different locations.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Impressively Modern" iconImage={iconLayoutAlt}>
              Impress your customers and staff with the most modern loaner
              vehicle management hub available. Free lifetime updates with all
              accounts, ensure your business is with the times.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard
              title="Tablet / Phone / Desktop"
              iconImage={iconResponsive}
            >
              Tablets for the service advisors, desktop for the shop manager,
              and on your phone where ever you are. LoanerHub works
              exceptionally well on all your devices.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
