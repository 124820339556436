import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

//import imgMobile from "../../assets/image/png/mobile.png";
import imgMobile from "../../assets/image/png/phoneLightClear.png";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";

import iconLayers from "../../assets/image/png/icon-layers.png";

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4} style={{ minWidth: "unset" }}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="#F7F7FB">
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className="pt-5 pt-lg-0">
              <Title>
                Make agreements
                <br className="d-none d-sm-block d-md-none d-lg-block" /> easy.
              </Title>
              <Text>
                LoanerHub enhances the traditional contract creation process by
                providing time-saving and <i>contact-free</i> automation.
                Respecting your time and health.
              </Text>
              <Box mt={4} pt={3}>
                <ContentCard
                  title="Collect Customer Details In Seconds"
                  iconImage={icon3dModel}
                  mb={4}
                >
                  {" "}
                  With LoanerHub, simply scan a driver’s license to
                  automatically collect your customer's details. Repeat
                  customer? Reuse details from a previous contract to get them
                  back on the road fast.
                </ContentCard>
                <ContentCard title="Contact-Free" iconImage={iconLayers}>
                  No more sharing of mobile devices, pen & paper, or touching
                  customer document. With LoanerHub customers can receive their
                  agreement via text message and sign on their own device. Scan
                  customer documents from your device to keep the loaner process
                  contract-free.
                </ContentCard>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
