import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import { Title, Button, Box, Text } from "../../components/Core";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Box bg="dark" className="position-relative" py={[4, null, null, 5, 6]}>
      <Container>
        <Row className="justify-content-center">
          <Col md="12">
            <Box className="text-center">
              <Title color="light">Create Fast, Manage Smart</Title>
              <Row className="justify-content-center">
                <Col md="8" lg="7">
                  <Text mb={4} color="light" opacity={0.7}>
                    Delight your customers and staff with loaner agreements
                    created with LoanerHub.
                  </Text>
                </Col>
              </Row>
              <div className="d-flex flex-column align-items-center pt-3">
                <a href="https://my.loanerhub.com/register">
                  <Button>Start 14 Days Free Trial</Button>
                </a>
                <Text
                  variant="small"
                  fontSize={"14px"}
                  color="light"
                  opacity={0.7}
                >
                  No credit card required
                </Text>
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
);

export default CTA;
