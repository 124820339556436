import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)`
  padding-top: 90px;
`;

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`;

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`;

const Faq = () => (
  <>
    {/* <!-- Faq section --> */}
    <SectionStyled bg="dark" pb="0 !important">
      <Container>
        <Row>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Do I get free updates?</FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Absolutely - All updates are free. We update our platform
                  based on feedback from our clients regularly. You get updates
                  for the lifetime of your subscription.
                </Text>
              </div>
            </FaqCard>
          </Col>

          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Will you provide support?</FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Yes. We take pride in the ease of using our platform. However,
                  sometimes we all need a little help. All accounts come with
                  free online support. Accounts with "Premium Support" get
                  priority reply.
                </Text>
              </div>
            </FaqCard>
          </Col>

          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Do you offer a discount?</FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Sometimes. We like to offer discounts to companies who
                  actively support their communities. If this is you, please
                  send us an email explaining why you think you qualify. We love
                  hearing the good acts our clients are doing.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Can I export my data?</FaqTitle>
                <Text variant="small" color="light" opacity={0.7}>
                  Absolutely. All accounts support CSV (Excel) dowload of data
                  along with contract prints.
                </Text>
              </div>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Faq;
